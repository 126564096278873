import { LayoutType } from '@verosource/myhealth-shared/src/state/withLayout'

const appConfig = {
  myhealthSharedVersion: process.env.REACT_APP_MYHEALTH_SHARED_VERSION,
  myhealthWebVersion: process.env.REACT_APP_MYHEALTH_WEB_VERSION,
  disableLocalStorage: false,
  disableLoginPage: true,
  onlyAllowEmbedded: true,
  disableHomePage: false,
  disableSettingsPage: true,
  disableAlerts: true,
  displayMaintenancePage: true,
  enablePrintText: true,
  filterMenu: {
    version: 1,
  },
  resources: {
    [LayoutType.IMAGING]: {
      enableNeedToKnow: true,
    },
    [LayoutType.IMMUNIZATIONS]: {
      enableNeedToKnow: true,
    },
    [LayoutType.LAB_RESULTS]: {
      enableNeedToKnow: true,
    },
    [LayoutType.MEDICATIONS]: {
      enableNeedToKnow: true,
      lowerDate: new Date(2016, 11),
    },
  },
  sideMenu: {
    disableLanguageToggle: true,
    disableLogout: true,
    version: 1
  },
  sideMenuV1: {
    disableLanguageToggle: true,
    disableLogout: true,
  }
};

export default appConfig;

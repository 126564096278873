const style = {
  'root::MedicationPage::PageList::Header': {
    text: {
      fontWeight: '700'
    },
    inactiveMedicationText: {
      fontWeight: '700'
    }
  },
  'root::MobileMedicationPage::PageList::Header': {
    text: {
      fontWeight: '700'
    },
    inactiveMedicationText: {
      fontWeight: '700'
    }
  },
  'root::MonthYearSelect': {
    container: {
      width: '100%',
    },
    titleContainer: {
      width: '100%',
      paddingLeft: 10,
    },
    buttonsContainer: {
      width: '100%',
      paddingRight: 10,
    },
    confirmButton: {
      paddingTop: 8,
      paddingBottom: 8,
    },
    cancelButton: {
      paddingTop: 6,
      paddingBottom: 6,
      paddingRight: 10,
      paddingLeft: 10,
    },
    errorContainer: {
      width: '100%',
    },
    cancelButtonErrorState: {
      paddingRight: 10,
    },
  },
  'root::MonthYearSelect::MonthSelect': {
    mobileContainer: {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
};

export default style;

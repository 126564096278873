import { LayoutType } from "@verosource/myhealth-shared/src/state/withLayout";

const theme = {
  colors: {
    brand: {
      primary: '#004D44',
      secondary: '#8A2935',
    },
    gradient: {
      primary: '#004D44',
      secondary: '#004D44',
    },
    primary: {
      50: '#DEFFFD',
      100: '#B3FFF6',
      200: '#86FEF0',
      300: '#5AFEEB',
      400: '#3DFEE5',
      500: '#30E5CB',
      600: '#21B29E',
      700: '#127F71',
      800: '#004D44',
      900: '#001B17',
    },
    secondary: {
      50: '#FFE8EC',
      100: '#F1C3C8',
      200: '#E39CA6',
      300: '#D67582',
      400: '#C94F5E',
      500: '#B03645',
      600: '#8A2935',
      700: '#631C26',
      800: '#3D1016',
      900: '#1B0206',
    },
    darkText: '#082F3B',
    lightText: '#65676B',
    alerts: {
      info: {
        primary: '#082F3B',
      },
      warning: {
        primary: '#F8C449',
        secondary: '#FEF6E4',
      },
      error: {
        primary: '#AF4D52',
        secondary: '#FBEEEE',
      },
    },
    [LayoutType.LAB_RESULTS]: {
      primary: '#225A91',
      secondary: '#2E78B9',
    },
    [LayoutType.MEDICATIONS]: {
      primary: '#8B5A5A',
      secondary: '#A57674',
    },
    [LayoutType.IMMUNIZATIONS]: {
      primary: '#006055',
      secondary: '#4C8F87',
    },
    [LayoutType.IMAGING]: {
      primary: '#3F96A6',
      secondary: '#59B3C0',
    },
  },
};

export default theme;

import app from './appConfig';
import network from './networkConfig';
import styles from './style';
import theme from './theme';
import translations from './translations';

const config = {
  app, network, styles, theme, translations,
};

export default config;
